/* unplugin-vue-components disabled */import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "m-panel__static"
};
var _hoisted_2 = {
  class: "m-panel__body"
};
var _hoisted_3 = {
  class: "m-panel__static"
};
export default {
  __name: 'MPanel',
  props: {
    modelValue: Boolean,
    panelMainClass: [String, Array],
    driverTargetClass: [String, Array],
    side: {
      type: String,
      default: 'right'
    } // left、right

  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    provide('closePanel', close);
    var cData = reactive({
      show: props.modelValue
    });
    watch(function () {
      return props.modelValue;
    }, function () {
      cData.show = props.modelValue;
    });
    watch(function () {
      return cData.show;
    }, function () {
      emit('update:modelValue', cData.show);
    });

    function close() {
      cData.show = false;
    }

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["m-panel", {
          'm-panel_show': _unref(cData).show,
          'm-panel_left': __props.side == 'left',
          'm-panel_right': __props.side == 'right'
        }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
          return close();
        }, ["self"]))
      }, [_createElementVNode("div", {
        class: _normalizeClass(["m-panel__main", __props.panelMainClass])
      }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "header")]), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "footer")])], 2)], 2);
    };
  }
};