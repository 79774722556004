/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "tabbar__main"
};
var _hoisted_2 = ["onClick"];
export default {
  __name: 'Tabbar',
  props: {
    modelValue: [Object, String, Number],
    list: Array
  },
  emits: ['update:modelValue', 'test'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cData = reactive({
      activeIdx: ''
    });

    function switchTab(item) {
      emit('update:modelValue', item);
    }

    watchEffect(function () {
      props.list.some(function (item, idx) {
        if (props.modelValue.id || props.modelValue.id == 0) {
          // modelValue是物件
          if (item.id == props.modelValue.id) {
            cData.activeIdx = idx;
            return true;
          }
        } else {
          // modelValue是 plain value
          if (item.id == props.modelValue) {
            cData.activeIdx = idx;
            return true;
          }
        }
      });
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["tabbar my-1", {
          'tabbar_inactive': !_unref(cData).activeIdx && _unref(cData).activeIdx !== 0
        }]),
        style: _normalizeStyle({
          '--tabbar-item-count': __props.list.length
        })
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "tabbar__hilighter",
        style: _normalizeStyle({
          '--tabbar-activeidx': _unref(cData).activeIdx
        })
      }, null, 4), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, function (item, idx) {
        return _openBlock(), _createElementBlock("div", {
          key: idx,
          class: "tabbar__link",
          onClick: function onClick($event) {
            return switchTab(item);
          }
        }, _toDisplayString(item.text), 9, _hoisted_2);
      }), 128))])], 6);
    };
  }
};